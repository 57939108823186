export const REQUIRED = 'This field is required';
export const THIS_FIELD_IS_REQUIRED = 'This field is required';
export const EMAIL_FORMAT = 'Please enter valid email address';
export const NAME_SPECIAL_CHARACTERS = 'Please enter valid first name';
export const LAST_NAME_SPECIAL_CHARACTERS = 'Please enter valid last name';
export const COMPANY_SPECIAL_CHARACTERS = 'Please enter valid company name';
export const US_PHONE_MAX_LENGTH_MESSAGE = 'Please enter valid number';
export const MESSENGER_MAX_LENGTH_MESSAGE = 'Please enter valid messenger';
export const USER_INFO_REGEX = /^[A-Za-z-'`’\s]+$/;
export const USER_INFO_REGEX_VALIDATION_MESSAGE = `The name shall consist only of Latin letters, spaces and hyphen`;
export const ACCEPT_TERMS = 'Please provide your consent to the processing of personal data';

export const MAX_ADDITIONAL_INFO_LENGTH = 10000;
export const MAX_BASE_FIELD_LENGTH = 64;
export const MAX_FREE_FORM_FIELD_LENGTH = 256;
export const MAX_EMAIL_LENGTH = 255;
export const MAX_US_PHONE_LENGTH = 14;
export const MAX_MESSENGER_LENGTH = 250;

export const ADDITIONAL_INFO_MESSAGE = 'Let us know how we can help';

export const MAX_FILE_SIZE = 10485760;
export const BYTES_IN_MB = 1048576;
export const MAX_ALL_FILE_SIZE_IN_BYTES = BYTES_IN_MB * 25;
export const MAX_FILE_NUM = 5;
export const FILE_TOO_BIG = `Unable to upload some attachments. Maximum file size is ${MAX_FILE_SIZE / BYTES_IN_MB}MB`;
export const FILES_TOO_BIG = `Unable to upload some attachments. Maximum files size is ${
  MAX_ALL_FILE_SIZE_IN_BYTES / BYTES_IN_MB
}MB`;
export const FILE_ATTACHMENTS_TOO_MANY = `Unable to upload some attachments. Maximum file number is ${MAX_FILE_NUM}`;
export const TYPES_NOT_SUPPORTED = 'Invalid file types.';

export const PHONE_REGEX = /^[0-9]{10,15}$/;
export const MIN_PHONE_LENGTH = 1;
export const MAX_PHONE_LENGTH = 15;
export const PHONE_FORMAT = 'Please enter valid number';

export const PERSONAL_INFO_AGREEMENT = 'Please provide your consent to the processing of personal data';

export const EMAILS_BLACK_LIST = [
  'mailinator',
  '10minutemail',
  'guerrillamail',
  'temp-mail',
  'fakemailgenerator',
  'dispostable',
  'mailnesia',
  'getnada',
  'yopmail',
  'tempail',
];

export const VACANCIES_LOAD_FAIL = 'The error has occurred. Please try to update the page.';
